const SVGTwo = () => (
  <svg
    width="103"
    height="103"
    viewBox="0 0 103 103"
    fill="none"
    style={{ width: '100%' }}
    xmlns="http://www.w3.org/2000/svg"
    role="img"
  >
    <title>Icon of sphere with the number two</title>
    <circle cx="51.5" cy="51.5" r="51.5" fill="#FC6C1C" />
    <path
      d="M61.8272 69H39.0911V68.72C46.1098 61.888 50.4778 57.1279 52.1951 54.4399C53.9498 51.7146 54.8271 49.1199 54.8271 46.6559C54.8271 44.8265 54.2485 43.2772 53.0911 42.0078C51.9711 40.7012 50.4031 40.0478 48.3871 40.0478C46.5578 40.0478 45.0644 40.6078 43.9071 41.7278C42.7871 42.8105 41.9284 44.2105 41.3311 45.9279L40.2671 45.6479C40.7897 42.9225 42.0591 40.3838 44.0751 38.0318C46.1284 35.6798 48.7791 34.5038 52.0271 34.5038C54.9018 34.5038 57.2725 35.3998 59.1392 37.1918C61.0058 38.9465 61.9392 41.2238 61.9392 44.0239C61.9392 47.4212 60.3898 50.5946 57.2912 53.5439C54.1925 56.4559 50.5525 59.6666 46.3711 63.176H56.0591C58.3738 63.176 59.9418 62.9146 60.7632 62.392C61.6218 61.8693 62.2939 60.9173 62.7792 59.5359H63.8432L61.8272 69Z"
      fill="white"
    />
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default SVGTwo;
