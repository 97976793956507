const SVGOne = () => (
  <svg
    width="103"
    height="103"
    viewBox="0 0 103 103"
    fill="none"
    style={{ width: '100%' }}
    xmlns="http://www.w3.org/2000/svg"
    role="img"
  >
    <title>Icon of sphere with the number one</title>
    <circle cx="51.5" cy="51.5" r="51.5" fill="#FC6C1C" />
    <path
      d="M55.0025 43.5759V64.576C55.0025 65.9573 55.3198 66.8347 55.9545 67.208C56.6265 67.544 57.8958 67.712 59.7625 67.712V69H42.6264V67.712C44.4931 67.712 45.7438 67.544 46.3784 67.208C47.0504 66.8347 47.3864 65.9573 47.3864 64.576V40.8878C47.3864 40.0665 47.2931 39.4878 47.1064 39.1518C46.9198 38.8158 46.4904 38.6478 45.8184 38.6478C45.6691 38.6478 45.3518 38.6852 44.8664 38.7598C44.4184 38.8345 43.5598 38.9465 42.2904 39.0958V37.8638L55.2825 34.5038C55.1705 35.4745 55.0958 36.6318 55.0585 37.9758C55.0212 39.3198 55.0025 41.1865 55.0025 43.5759Z"
      fill="white"
    />
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default SVGOne;
