const SVGHero = () => (
  <svg
    width="470"
    height="208"
    viewBox="0 0 470 208"
    fill="none"
    style={{ width: '100%' }}
    xmlns="http://www.w3.org/2000/svg"
    role="img"
  >
    <title>Graphic illustration of spheres with lines expanding out</title>
    <path d="M162.995 110.914L296.759 186.258" stroke="#FC6B1A" strokeWidth="1.20968" />
    <path d="M162.995 109.775L356.64 84.9125" stroke="#FC6B1A" strokeWidth="1.20968" />
    <path d="M162.669 110.262L405.414 164.295" stroke="#FC6B1A" strokeWidth="1.20968" />
    <path d="M162.344 109.286L423.665 17.9823" stroke="#FC6B1A" strokeWidth="1.20968" />
    <g style={{ mixBlendMode: 'multiply' }}>
      <circle cx="55.6454" cy="110.914" r="55.6454" fill="#FCB48D" />
    </g>
    <g style={{ mixBlendMode: 'multiply' }}>
      <circle r="100.754" transform="matrix(1 0 0 -1 162.344 106.048)" fill="#FCB48D" />
    </g>
    <path
      d="M439.595 17.0378C439.595 25.3305 432.864 32.0532 424.56 32.0532C416.257 32.0532 409.526 25.3305 409.526 17.0378C409.526 8.74522 416.257 2.02247 424.56 2.02247C432.864 2.02247 439.595 8.74522 439.595 17.0378Z"
      fill="#FC6B1A"
      stroke="#FC6B1A"
      strokeWidth="0.222194"
    />
    <path
      d="M382.582 81.2893C382.582 89.5819 375.851 96.3047 367.547 96.3047C359.243 96.3047 352.512 89.5819 352.512 81.2893C352.512 72.9967 359.243 66.2739 367.547 66.2739C375.851 66.2739 382.582 72.9967 382.582 81.2893Z"
      fill="#FC6B1A"
      stroke="#FC6B1A"
      strokeWidth="0.222194"
    />
    <path
      d="M469.868 112.142C469.868 120.435 463.137 127.157 454.833 127.157C446.529 127.157 439.798 120.435 439.798 112.142C439.798 103.849 446.529 97.1267 454.833 97.1267C463.137 97.1267 469.868 103.849 469.868 112.142Z"
      fill="#FC6B1A"
      stroke="#FC6B1A"
      strokeWidth="0.222194"
    />
    <path
      d="M415.57 163.712C415.57 172.005 408.839 178.727 400.536 178.727C392.232 178.727 385.501 172.005 385.501 163.712C385.501 155.419 392.232 148.697 400.536 148.697C408.839 148.697 415.57 155.419 415.57 163.712Z"
      fill="#FC6B1A"
      stroke="#FC6B1A"
      strokeWidth="0.222194"
    />
    <path
      d="M318.591 192.561C318.591 200.854 311.86 207.577 303.557 207.577C295.253 207.577 288.522 200.854 288.522 192.561C288.522 184.269 295.253 177.546 303.557 177.546C311.86 177.546 318.591 184.269 318.591 192.561Z"
      fill="#FC6B1A"
      stroke="#FC6B1A"
      strokeWidth="0.222194"
    />
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default SVGHero;
