const SVGThree = () => (
  <svg
    width="103"
    height="103"
    viewBox="0 0 103 103"
    fill="none"
    style={{ width: '100%' }}
    xmlns="http://www.w3.org/2000/svg"
    role="img"
  >
    <title>Icon of sphere with the number three</title>
    <circle cx="51.5" cy="51.5" r="51.5" fill="#FC6C1C" />
    <path
      d="M46.7685 41.5038C45.3871 41.5038 44.3605 41.7652 43.6885 42.2878C43.0165 42.8105 42.4378 43.8745 41.9524 45.4799H41.0004L42.5125 35.0638H62.5606V35.2878L50.0165 49.5679C53.0032 49.0079 55.8219 49.5119 58.4725 51.0799C61.1232 52.6106 62.4486 55.1306 62.4486 58.6399C62.4486 62 61.1792 64.6693 58.6405 66.648C56.1019 68.5893 53.3952 69.56 50.5205 69.56C47.4218 69.56 44.9018 68.72 42.9605 67.04C41.0191 65.3226 39.6938 63.3066 38.9844 60.992L40.1604 60.3759C40.8698 62.28 41.9151 63.7733 43.2965 64.856C44.7151 65.9386 46.3765 66.48 48.2805 66.48C50.4085 66.48 52.1072 65.7706 53.3765 64.352C54.6832 62.9333 55.3365 61.0853 55.3365 58.8079C55.3365 56.2693 54.4032 54.3839 52.5365 53.1519C50.7072 51.8826 48.6725 51.5466 46.4325 52.1439L46.2645 51.8079L55.0005 41.5038H46.7685Z"
      fill="white"
    />
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default SVGThree;
