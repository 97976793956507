import { SignupLink } from 'components/Links';
import { Notice } from './Notice';

export const SMSFNotice = () => (
  <Notice
    header="Simplify your SMSF admin"
    subHeader="Try our award-winning SMSF portfolio tracker"
    button={<SignupLink asButton />}
  />
);
