import { useContentfulPage } from 'hooks/useContentfulPage';

import { SignupLink } from 'components/Links';

import { Notice } from './Notice';

import type { ReactElement, ReactNode, FC } from 'react';
import type { Color } from 'styled/types';

interface CTANoticeProps {
  background?: Color;
  header?: string;
  note?: ReactNode;
  /** Falls back to a sign up link */
  button?: ReactElement;
}

const DEFAULT_HEADER = 'Try Sharesight today';
const DEFAULT_NOTE = 'Track 10 holdings for free.';

export const CTANotice: FC<CTANoticeProps> = ({ background = 'callout', header, note, button }) => {
  const {
    translations: { ctaNoticeHeader, ctaNoticeNote },
  } = useContentfulPage();

  /*
   * NOTE: We use a null-coalesce here as we should be able to disable this.
   * Passing `header=false` or a translation with `footerNoticeHeader=false` will disable this.
   */
  const headerText = header ?? ctaNoticeHeader ?? DEFAULT_HEADER;
  const noteText = note ?? ctaNoticeNote ?? DEFAULT_NOTE;

  return (
    <Notice
      background={background}
      header={headerText}
      subHeader={noteText}
      button={button || <SignupLink asButton />}
    />
  );
};
