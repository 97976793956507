import styled from 'styled-components';

import { getDimension } from 'styled/helpers/getDimension';

export const Header = styled.h4`
  & + * {
    // Only have a margin if a subsequent element exists.
    margin-top: ${getDimension('size2')};
  }
`;
