import { ExternalLink } from 'components/Links';

import OneIcon from 'images/icons/one';
import TwoIcon from 'images/icons/two';
import ThreeIcon from 'images/icons/three';

import { useGatsbyImage } from 'hooks/useGatsbyImage';

import type { ReactElement } from 'react';
import type { Translations } from 'helpers/mergeTranslations';

export interface getBenefitsCardsProps {
  benefitsImgThree: ReactElement;
}

export interface getStepCardsProps {
  formLink: string;
}

export const getInfoCards = ({ freeUsers }: Translations) => [
  {
    cardIdentifier: 'track-1',
    title: freeUsers,
    text: 'Global users, with more joining every day',
  },
  {
    cardIdentifier: 'track-2',
    title: '100+',
    text: 'Countries represented in our userbase',
  },
  {
    cardIdentifier: 'track-3',
    title: '50%',
    text: 'Commission on first years revenue',
  },
];

export const useBenefitsCards = () => [
  {
    cardIdentifier: 'track-1',
    title: 'Leading portfolio tracker',
    text: 'Lead your audience from a place of anxiety and loss of control, to becoming rational and data-driven investors.',
    image: useGatsbyImage({
      name: 'affiliates/lightbulb',
      alt: 'Illustration of a lightbulb with a cog inside',
    }),
  },
  {
    cardIdentifier: 'track-2',
    title: 'Marketing support',
    text: 'Your signups get the same onboarding and support, with consistent encouragement to try an upgraded plan.',
    image: useGatsbyImage({
      name: 'affiliates/letter',
      alt: 'Illustration of an open letter',
    }),
  },
  {
    cardIdentifier: 'track-3',
    title: 'Transparent payouts',
    text: 'Receive commission payments every month, upon reaching a minimum threshold, through bank transfer.',
    image: useGatsbyImage({
      name: 'affiliates/orbiting-dollar',
      alt: 'Graphic illustration of a coin',
    }),
  },
];

export const getStepCards = ({ formLink }: getStepCardsProps) => [
  {
    cardIdentifier: 'track-1',
    title: 'Apply',
    text: (
      <>
        Complete this <ExternalLink to={formLink}>short form</ExternalLink>. We’ll follow-up with
        you and share more details on the program.
      </>
    ),
    image: <OneIcon />,
  },
  {
    cardIdentifier: 'track-2',
    title: 'Promote',
    text: 'Share your affiliate link with your followers. We’ll provide imagery and banners you can use.',
    image: <TwoIcon />,
  },
  {
    cardIdentifier: 'track-3',
    title: 'Earn',
    text: 'Receive up to 50% commission for every new subscription – up to 12 months’ worth.',
    image: <ThreeIcon />,
  },
];
